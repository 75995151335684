import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './AspectRatioImage.module.scss';

const AspectRatioImage = ({ className, ratio, src, ...props }) => (
  <div className={cls(styles.root, className)} {...props}>
    <div
      className={styles.aspectRatio}
      style={{ paddingBottom: `${Number((1 / ratio) * 100).toFixed(2)}%` }}
    >
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${src})` }}
      />
    </div>
  </div>
);

AspectRatioImage.propTypes = {
  className: PropTypes.string,
  ratio: PropTypes.number,
  src: PropTypes.string,
};
AspectRatioImage.defaultProps = {
  className: undefined,
  ratio: 1,
  src: '',
};

export default AspectRatioImage;
