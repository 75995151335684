import React from 'react';
import { graphql } from 'gatsby';
import cls from 'classnames';
import { formatArticle, formatArticles } from '../formatters/article';
import { formatDate } from '../utils/date';
import Link from '../components/Link';
import Body from '../components/Body';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Section from '../components/Section';
import ContentWithSidebar from '../components/ContentWithSidebar';
import AspectRatioImage from '../components/AspectRatioImage';
import styles from './ArticleTemplate.module.scss';

const ArticleTemplate = ({ data }) => {
  const { id, title, date, body, metaDescription, metaTitle } = formatArticle(
    data,
  );

  return (
    <Layout>
      <Meta title={metaTitle || title} description={metaDescription} />
      <Section>
        <ContentWithSidebar
          contentAfter={
            <div className="box">
              <h4 className="has-text-centered">Recent Articles</h4>
              {formatArticles(data)
                .filter(article => article.id !== id)
                .map(article => (
                  <Link
                    className={styles.recent}
                    key={article.id}
                    to={article.path}
                  >
                    <div className="columns is-gapless">
                      <div className="column is-one-third">
                        <AspectRatioImage ratio={3 / 2} src={article.image} />
                      </div>
                      <div className="column is-two-thirds">
                        <div className={styles.recentLink}>{article.title}</div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          }
        >
          <div className={styles.heading}>
            <h1 className={styles.title}>{title}</h1>
            <span className={cls('tag is-medium', styles.date)}>
              {formatDate(date)}
            </span>
          </div>
          <Body content={body} />
        </ContentWithSidebar>
      </Section>
    </Layout>
  );
};

export default ArticleTemplate;

export const articleFragment = graphql`
  fragment ArticleFragment on MarkdownRemark {
    id
    fields {
      path
      slug
    }
    frontmatter {
      title
      date
      image
      metaDescription
      metaTitle
    }
    html
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...ArticleFragment
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "article" }, hidden: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 6
    ) {
      nodes {
        ...ArticleFragment
      }
    }
  }
`;
