import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ children }) => (
  <section className="section">
    <div className="container">
      <div className="content">{children}</div>
    </div>
  </section>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Section;
