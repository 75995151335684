import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from './Sidebar';
import styles from './ContentWithSidebar.module.scss';

const ContentWithSidebar = ({ contentBefore, contentAfter, children }) => (
  <div className="columns is-variable is-8-desktop">
    <div className="column is-8">{children}</div>
    <aside className="column is-4">
      <div className={styles.sidebar}>
        {contentBefore}
        <Sidebar />
        {contentAfter}
      </div>
    </aside>
  </div>
);

ContentWithSidebar.propTypes = {
  contentBefore: PropTypes.node,
  contentAfter: PropTypes.node,
  children: PropTypes.node.isRequired,
};
ContentWithSidebar.defaultProps = {
  contentBefore: undefined,
  contentAfter: undefined,
};

export default ContentWithSidebar;
