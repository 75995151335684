import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cls from 'classnames';
import { formatReviews } from '../formatters/review';
import Link from './Link';
import ExternalLink from './ExternalLink';
import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { template: { eq: "review" }, hidden: { ne: true } }
        }
        sort: { order: DESC, fields: [frontmatter___score] }
        limit: 5
      ) {
        nodes {
          ...ReviewFragment
        }
      }
    }
  `);

  return (
    <div className={cls('box', styles.root)}>
      <h4 className="has-text-centered">Top 5 VIN Decoders</h4>
      {formatReviews(data).map(review => (
        <div
          key={review.id}
          className={cls(
            'columns is-desktop v-centered is-multiline',
            styles.item,
          )}
        >
          <div className={cls('column', styles.logoWrapper)}>
            <ExternalLink className={styles.logoLink} href={review.url}>
              <img
                className={styles.logo}
                src={review.logo}
                alt={review.title}
              />
            </ExternalLink>
          </div>
          <div className={cls('column', styles.actions)}>
            <ExternalLink
              className={cls('button is-small has-text-white', styles.visit)}
              href={review.url}
            >
              Visit site
            </ExternalLink>
            <Link
              className={cls('is-size-7', styles.reviewLink)}
              to={review.path}
            >
              Read review
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
