import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystringify';

const CLICK_ID_PARAM = 'gclid';

const ExternalLink = ({ href, ...props }) => {
  const [fullHref, setFullHref] = useState(href);

  useEffect(() => {
    const windowQueryParams = qs.parse(window.location.search);
    const clickId = windowQueryParams[CLICK_ID_PARAM];
    if (!clickId) {
      return;
    }
    const hrefQueryParams = qs.parse(href.split('?')[1] || '');
    setFullHref(
      href + qs.stringify({ ...hrefQueryParams, gid: clickId }, true),
    );
  }, [href]);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      href={fullHref}
      rel="nofollow noopener noreferrer"
      target="_blank"
      {...props}
    />
  );
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default ExternalLink;
